import React from 'react'
import Layout from '../components/layout-default';
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import ErrorBoundary from "../components/ErrorBoundary";
import MindBodyWidget from "../components/mindbody";

const crumbs = [
    {
        label: "Home",
        link: "",
    },
    {
        label: "Kids",
        link: "kids",
    },
];

class Kids extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.eventbrite.com/static/widgets/eb_widgets.js";
        script.async = true;

        script.onload = () => {
            const exampleCallback = () => {
                console.log('Order complete!');
            };

            window.EBWidgets.createWidget({
                // Required
                widgetType: 'checkout',
                eventId: '902949816207',
                iframeContainerId: 'eventbrite-widget-container-902949816207',

                // Optional
                iframeContainerHeight: 425,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
                onOrderComplete: exampleCallback  // Method called when an order has successfully completed
            });
        };

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        const script = document.querySelector('script[src="https://www.eventbrite.com/static/widgets/eb_widgets.js"]');
        if (script) {
            document.body.removeChild(script);
        }
    }
    render() {
        return (
            <Layout
                title="Kids | RF Dance"
                pathname="kids/"
                noHero="no-hero"
                className="news_page"
            >
                <Hero
                    titleRed={`RF`}
                    titleWhite={`Kids`} />
                <div className="content-block">
                    <div className="container">
                        <Breadcrumbs crumbs={crumbs} />
                        <div id="eventbrite-widget-container-902949816207"></div>
                        <div className='news_area'>
                            <div className='english-event'>
                                {/* English */}
                                <div className="news_item">
                                    <div className='news_content'>
                                        <h3 className='news_heading'>Kids Latin Dance Summer Intensive at RF Dance Center Salsa, Bachata & More! </h3>
                                        <div className='news_content_area'>
                                            <p>Give your kids (ages 7-17) a summer experience they'll always remember at RF Dance Center's Latin Dance Summer Intensive! Our expert instructors, with years of experience teaching Latin rhythms, will guide your children through the exciting world of Salsa, Bachata, and Cumbia.
                                            </p>
                                            <p>Unlike other summer camps, our Latin Dance Summer Intensive at RF Dance Center is a unique opportunity for your kids. We specialize in Latin dance, providing top-quality instruction in a fun, engaging environment. Your children will not only learn dance but also develop lifelong skills, make new friends, and have a blast while school's out!
                                            </p>
                                            <p>Parents rave about our programs!
                                            </p>
                                            <p>Take advantage of this incredible opportunity. Sign up now before prices go up and secure your child's spot!
                                            </p>
                                            <p>
                                                🌟BONUS: Early registrants (Before May 31st) receive a FREE RF KIDS T-shirt! 👕
                                            </p>
                                            <p>Give your kids the gift of dance this summer. Register today!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Spanish */}
                                <div className="news_item">
                                    <div className='news_content'>
                                        <h3 className='news_heading'>Intensivo de Danza Latina para Niños en RF Dance Center
                                            ¡Salsa, Bachata y Más!
                                        </h3>
                                        <div className='news_content_area'>
                                            <p>Regala a tus hijos (de 7 a 17 años) una experiencia de verano inolvidable en el Intensivo de Danza Latina de RF Dance Center. Nuestros instructores expertos, con años de experiencia enseñando ritmos latinos, guiarán a tus hijos a través del emocionante mundo de la Salsa, Bachata y Cumbia.
                                            </p>
                                            <p>A diferencia de otros campamentos de verano, nuestro Intensivo de Danza Latina en RF Dance Center es una oportunidad única para tus hijos. Nos especializamos en danza latina, ofreciendo instrucción de alta calidad en un ambiente divertido y comprometedor. Tus niños no sólo aprenderán a bailar, sino que también desarrollarán habilidades para toda la vida, harán nuevos amigos y se divertirán en grande mientras no hay clases.
                                            </p>
                                            <p>Los padres hablan maravillas de nuestro programa:
                                            </p>
                                            <p>Aprovecha esta increíble oportunidad. ¡Inscríbete ahora antes de que suban los precios y asegura el lugar de tu hijo!
                                            </p>
                                            <p>
                                                🌟BONUS: ¡Los inscritos temprano (antes de Mayo 31) reciben una playera RF KIDS GRATIS!👕
                                            </p>
                                            <p>Regala a tus hijos el don de la danza este verano. ¡Regístrate hoy!
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Kids